.intake .LandingStep,
.intake .VolviaLandingStep {
  button.if.button {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .language-selector {
    margin: 15px 0;
  }
  .header-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }
  .header-logo {
    text-align: center;
    height: 50px;
  }
  .if.logo {
    width: 50px !important;
    height: 50px !important;
    background-size: 50px 50px !important;
  }

  .brand-logo {
    max-height: 50px;
    max-width: 290px;
  }

  .if.text.disclaimer.made-by {
    margin-top: 2rem;
    margin-bottom: 3rem;
    .brand-logo {
      height: 2rem;
      position: relative;
      top: 0.6rem;
      margin-left: 0.4em;
    }
  }
  .landing-image-container {
    margin: 15px 0;
  }
  .landing-image {
    width: 80%;
    max-width: 500px;
  }
  .field-consent-checkbox {
    margin: 15px 0;
  }

  .landing-container {
    text-align: center;
  }

  .loader-container {
    margin-bottom: 2rem;
  }

  .field-consent-checkbox {
    max-width: 100% !important;
    margin: -15px auto 15px auto;
    text-align: center;
    label {
      height: auto;
      margin: 0;
      font-size: 15px;
      &::before {
        margin-right: 0.6em;
      }
    }
    fieldset {
      margin: 0 auto;
    }
    a {
      margin: 0 0.3em;
    }
    span {
      text-align: left;
    }
  }
  .if.text.disclaimer {
    font-size: 0.9rem !important;
    line-height: 1.5rem !important;
    display: block;
    margin: 15px auto !important;
    text-align: center;
    a {
      margin: 0 0.3em;
      &::after {
        position: relative;
        top: 0.15em;
        width: 1em !important;
        height: 1em !important;
        background-size: 1em 1em !important;
        margin-left: 0.3em !important;
      }
    }
  }

  .accessibility-statement-container {
    display: flex;
    justify-content: center;

    .if.standalone a {
      font-size: 0.9375rem;
    }
  }
  .consent-container {
    text-align: left;
    .back-arrow {
      position: relative !important;
      display: inline-flex !important;
      padding: 0;
    }
    small {
      font-size: 0.9rem;
      line-height: 0.9;
    }
  }
}
