.progress-container {
  height: 35px;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
}

.bar-container {
  background-color: #e0e0de;
  height: 10px;
}

.progres-bar-styles {
  height: 10px;
  background-color: #0054f0;
  border-radius: inherit;
  text-align: right;
  transition: width 0.2s;
}
