.description-length {
  text-align: left;
  display: block;
  margin: -2rem 0 2rem;
}

.description-container {
  display: flex;
  justify-content: center;
  .description-input-container {
    .if.input-wrapper {
      width: 100%;
      max-width: unset !important;
      textarea.if.textarea {
        width: 100%;
      }
    }
  }
}

.description-input-container {
  width: 100%;
}
