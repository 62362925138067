.photo-inspection-header {
  min-height: unset !important;
  flex: 0 0 auto;

  .icon-header-container {
    display: flex;
    padding-left: 32px;
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .header-text {
    padding-left: 16px !important;
    padding-top: 14px !important;
  }

  .header-logo {
    margin: 0 !important;
  }

  .header-menu {
    position: relative;
    top: -0.6em;
  }

  .if.logo {
    width: 50px !important;
    height: 50px !important;
    background-size: 50px 50px !important;
  }

  .header-container {
    display: flex;
    height: 100px;
    justify-content: space-between;
  }

  .header-links-container {
    display: flex;

    .dropdown-container {
      margin-top: -5px;
    }

    a {
      color: #333333 !important;
      text-decoration: none;
      font-size: 16px;
    }

    .dropdown {
      width: 9em;
      height: 2.4rem;
      margin-left:  1em;
      padding: 0 0.8em;
    }
  }

  .username-icon {
    display: block;
    width: 16px;
    height: 16px;
    margin: auto;
    margin-right: 8px !important;
    background-size: unset !important;
  }

  .logout-icon {
    display: block;
    width: 16px;
    height: 16px;
    margin: auto;
    margin-right: 8px !important;
    background-size: unset !important;
  }

  .auth-container {
    display: flex;
    height: 24px;
    margin-bottom: 38px;
    margin-top: 38px;
  }

  .log-container {
    display: flex;
    cursor: pointer;
    height: 24px;
    margin-bottom: 38px;
    margin-top: 38px;
  }
  .user-name {
    display: flex;
    height: 24px;
    padding-right: 32px;
    align-items: center;
    color: #333333 !important;
    font-size: 16px;
  }
  .logout {
    display: flex;
    height: 24px;
    align-items: center;
    padding-right: 32px;
    color: #333333 !important;
    font-size: 16px;
  }
}
