@import "~@if-design-system/color/src/color-variables";
$notification-border-opacity: 1;

@keyframes fade-in {
  from {
    left: 32em;
    opacity: 0;
  }
  to {
    left: 0em;
    opacity: 1;
  }
}

.if.notifications {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  width: 30rem;
  flex-direction: column;
  overflow: hidden;
  z-index: 9000;
  .notification {
    position: relative;
    font-size: 1.1em;
    padding: 1em 2em 1em 1em;
    margin-top: 1rem;
    opacity: 1;
    animation-duration: 0.5s;
    animation-name: none;
    animation-fill-mode: forwards;
    border: 2px solid;
    &.fade-out {
      animation-name: fade-out;
    }
    &.fade-in {
      animation-name: fade-in;
    }
    .icon {
      width: 1.3em;
      height: 1.3em;
      display: inline-block;
      margin-right: 0.4em;
      position: relative;
      top: 0.3em;
      background-size: contain !important;
    }
    button.close {
      position: absolute;
      top: 0.3em;
      right: 0.3em;
      width: 1.5em;
      height: 1.5em;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      font-size: 1.3em;
      &:hover {
        background-color: white;
      }
    }
    &.Error {
      background-color: $color-lightRed-infographic;
      border-color: rgba($color-red-status, $notification-border-opacity);
    }
    &.Success {
      background-color: $color-lightGreen-infographic;
      border-color: rgba($color-green-status, $notification-border-opacity);
    }
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
