div.dev-menu
{
  position: fixed;
  right: 1em;
  top: 1em;
  bottom: 1em;
  width: 10em;
  background: #f6f3f0;
  z-index: 9000;
  padding: 1em;
  display: flex;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
  zoom: 0.7;
  button
  {
    padding: 0.4em;
    width: 100%;
    text-align: left;
    cursor: pointer;
  }
  .spacer
  {
    height: 1.3em;
  }
}
