.workshop-ui .admin-workshop-feedback {
  .feedback {
    .icon {
      width: 1em;
      height: 1em;
      display: inline-block;
      background-size: 100%;
      position: relative;
      top: 3px;
    }
    margin-bottom: 1em;
  }
}
