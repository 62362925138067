@import "~@if-design-system/color/src/background";

html.intake {
  .SummaryStep {
    .error-text {
      color: #aa0000;
      .icon {
        height: 1em;
        width: 1em;
        display: inline-block;
        position: relative;
        top: 0.1em;
        margin-right: 0.3em;
      }

      .section-header {
        margin: 0px 0px 10px;
        text-align: center;
      }
    }
  }

  .summary-information-section {
    display: grid;
    grid-template-columns: 50% auto;
    grid-gap: 10px;

    .header,
    .data {
      overflow-wrap: break-word;
    }
  }

  .summary-photo-header {
    margin: 0px;
    text-align: center;
    font-variation-settings: "wght" 64;
  }

  .summary-photos-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .summary-photo-container,
    .extra {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 0;
      margin-bottom: 1em;

      .if.summary-photo-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }

      .summary-images-container {
        width: 100%;
        max-height: inherit;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media screen and (min-width: 768px) {
          max-height: 225px;
        }

        img {
          max-height: 100%;
          min-width: 100%;
          object-fit: cover;
          vertical-align: bottom;
        }
      }

      @media screen and (min-width: 768px) {
        width: calc(50% - 0.5rem);
      }
    }

    .extra {
      width: 100%;
      align-self: flex-end;

      .if.summary-photo-header {
        &.for-1-photo {
          width: 100%;
          @media screen and (min-width: 768px) {
            width: 50%;
          }
        }
      }

      .summary-images-container {
        justify-content: space-between;

        img {
          height: auto;
          min-width: 100%;
          max-width: 100%;
          object-fit: cover;
          @media screen and (min-width: 768px) {
            max-height: 225px;
            min-width: calc(50% - 0.5rem);
            max-width: calc(50% - 0.5rem);
          }
        }
      }
    }
  }

  .summary-information-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    justify-content: center;
    .header {
      text-align: right;
    }
    .data {
      text-align: left;
    }
  }

  .SummaryStep {
    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .if.button.tertiary {
      min-width: initial;
      max-width: initial;
      width: auto;
      height: 2em;
      padding: 0.2em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      border: 2px solid transparent;
      box-shadow: inset 0 0 0 1px transparent;
    }
  }

  .summary-information-container {
    display: block;
    .header {
      text-align: left;
    }
    .data {
      text-align: left;
    }
  }

  .summary-information-container,
  .summary-description-container p {
    padding: 1em;
    background: $color-lightestBeige-background;
  }

  .info-line {
    display: flex;
    flex-direction: column;

    &.span-full-row {
      grid-column-end: span 2;
    }
  }
}
