.sidenav-item-container {
  display: flex;
  justify-content: space-between;
  padding-left: 29.2px;
  padding-right: 21px;
  height: 2.875em;
  cursor: pointer;
  align-items: center;
  &.selected {
    background-color: #6e625e;
    .text,
    .counter {
      color: #ffffff;
      font-family: If Sans Bold, Arial, sans-serif !important;
      font-weight: 400;
      font-variation-settings: "wght" 126;
    }
  }
  .counter {
    color: #321d11;
  }

}

a.admin-load-more {
  font-size: 0.7em !important;
  display: block !important;
  margin: 0 auto !important;
  padding: 0.3em 0.7em !important;
  height: auto !important;
  width: fit-content !important;
  min-width: 0 !important;
}
