.submit-btn-holder
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 100%;
}

.back-next-btn-holder, .submit-status-holder {
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: baseline;
  .loaderContainer
  {
    position: relative;
    top: -0.6em;
    flex-grow: 1;
    text-align: right;
  }
  .cancel-button
  {
    width: auto;
    flex-basis: 100%;
    margin: 0 auto 1em !important;
  }
}
.submit-status-holder
{
  padding: 0;
  width: 100%;
  .submit-status-text
  {
    flex: 0 1 auto;
  }
  .submit-status-progress
  {
    flex: 1 1 auto;
    height: 0.35em;
    position: relative;
    top: -0.1em;
    border-radius: 0.2em;
    background: #cdcdcd;
    overflow: hidden;
    margin: 0 0 1.5em 1em;
    .bar
    {
      background: #0054f0;
      height: 100%;
      width: 0%;
    }
  }
  .loaderContainer
  {
    flex: 0 1 auto;
    margin-left: 2em;
  }
}

.start-btn-holder {
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.double-button {
  min-width: 130px;
  padding: 0 20px;
}

@media only screen and (max-width: 600px) {
  .single-button {
    display: contents;
  }

  .double-button {
    display: contents;
  }
}
