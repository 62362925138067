
.card-container {
  width: 100%;
  margin: 1em 0;
  > .image {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .image-container {
      width: 100%;
      max-height: 40vh;
      margin: 0 auto;
      object-fit: contain;
      img {
        max-width: 100%;
        max-height: 35vh;
        margin: 0 auto;
        border: 2px dashed gray;
        padding: 4px;
        &.placeholder {
          padding: 0;
          border: 2px solid #0054f0;
        }
      }
    }

    .image-subtitle {
      font-size: 0.8em;
      margin-top: 1em;
      .file-name {
        padding: 0 0.7em 0 1.8em;
        background-position: 0 50%;
        position: relative;
        margin-right: 0.7em;
        max-width: calc(100% - 7em);
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.1em;
        top: 0.15em;
        &::after {
          content: "";
          display: block;
          height: 1rem;
          width: 1px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          border-right: 1px solid rgba(110, 98, 94, 0.4);
        }
      }
      .file-action.delete {
        //background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg class='nc-icon-wrapper' stroke-linecap='square' stroke-width='1.5' fill='none' stroke='%230054f0' stroke-miterlimit='10'%3E%3Cpath d='M27 5L5 27M27 27L5 5'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 70%;
        background-color: transparent;
        height: 1.8em;
        width: 1.8em;
        border: 0;
        position: relative;
        top: 0.5em;
        margin-left: 0.7em;
        cursor: pointer;
        &:hover,
        &:focus {
          filter: grayscale(1) brightness(0.8) contrast(1.8);
        }
      }
    }
  }
}

.brand-volvia .card-container > .image {
  .image-container {
    img {
      border-color: #5e7676;
    }
  }
  .image-subtitle .file-action.delete {
    filter: hue-rotate(-50deg) grayscale(0.6);
    &:hover,
    &:focus {
      filter: grayscale(1) brightness(0.8) contrast(1.8);
    }
  }
}
.photo-container {
  img {
    width: 800px;
    height: 600px;
  }
}

#photo-display {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  border: 1px solid red;
  position: relative;
  background: no-repeat 50% 50%;
  background-size: contain;
}

#camera-photo {
  width: 3840px;
  height: 2160px;
}

#take-photo {
  position: absolute;
  top: 0;
  z-index: 9000;
}

.photo-upload {
  .previews {
    width: 100%;
    .preview-container {
      position: relative;
      display: inline-block;
      .preview {
        width: 100%;
        max-width: 500px;
        margin: auto;
        border: 2px dashed #909090;
      }
      .remove-photo {
        position: absolute;
        border-radius: 3em;
        width: 2.4em;
        height: 2.4em;
        min-width: 40px;
        min-height: 40px;
        right: -1.2em;
        top: -1.2em;
        border: 2px solid #a30;
        background-color: #a30;
        cursor: pointer;
        &:hover {
          background-color: invert(#f6f3f0);
          border-color: invert(#a30);
          filter: invert(1);
        }
        .icon {
          height: 1.8em;
          background-size: 1.3em;
        }
      }
    }
  }

  .input-error {
    margin-bottom: 2rem;
    padding-left: 1.3em;
    background-position-x: 0 !important;
    text-align: center;
  }

  .take-photo-btn {
    margin: auto 0;
  }
  .delete-button {
    margin: auto;
  }

  .photo-step-image-loader {
    width: 100%;
    height: 200px;
  }

  .photo-image-container {
    width: 100%;
    text-align: center;
  }

  .photo-image {
    width: 100%;
    max-width: 500px;
    margin: auto auto 1rem;
    border: 2px dashed #909090;
  }

  .photo-upload-container {
    text-align: center;
    align-items: center !important;
    max-width: 100% !important;
  }

  .validation-error-container {
    margin: auto auto 20px;
    width: fit-content;
    display: block;
  }

  .image-icon {
    padding: 20px;
  }
  [data-whatinput="keyboard"] & .remove-photo:focus {
    outline: 3px solid #f09c00;
    outline-offset: 1px;
  }
  input[type="file"].if ~ label.take-photo-btn.if {
    color: white !important;
  }
  [data-whatinput="keyboard"] & input[type="file"].if:focus ~ label.take-photo-btn.if {
    outline: 3px solid #f09c00;
    outline-offset: 1px;
  }
}
.delete-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;
  .window {
    background-color: #f6f3f0;
    h3 {
      margin: 0.4em 1em 0.7em !important;
      text-align: center;
    }
    button.if.button {
      min-width: 0;
      width: auto;
      margin: 0 1em 1em 1em;
    }
    .button + .button {
      margin-left: -0.5em !important;
    }
  }
}
