$color-bg: #ffffff;
$color-text: #000000;
$color-border: #f09c00;
$color-button: #5e7676;
$color-error: #c14523;

html.intake.brand-volvia {
  .unbranded {
    display: none;
  }
  .branded.brand-volvia {
    display: initial;
  }

  font-family: VolvoNovumRegular, sans-serif !important;

  .loader {
    fill: $color-button !important;
  }

  #root {
    background-color: $color-bg !important;
  }
  .progres-bar-styles {
    background-color: $color-button !important;
  }
  .if {
    .logo {
      background-image: url("/img/volvia/logo.svg");
    }

    font-family: VolvoNovumRegular, sans-serif !important;

    &.basic-text {
      font-weight: 600 !important;
      font-size: 0.95rem !important;
    }

    &.input-wrapper.is-focused label,
    &.input-wrapper:focus-within label {
      color: $color-text !important;
    }

    &.textarea,
    .input-field,
    textarea.if,
    textarea.if.textarea,
    input[type="date"].if {
      background-color: $color-bg !important;
      border-radius: 4px !important;
    }

    &.textarea.is-active,
    &.textarea:not(:placeholder-shown):focus,
    .input-field.is-active,
    .input-field:not(:placeholder-shown):focus,
    &.textarea.is-focused,
    &.textarea:focus,
    textarea.if.is-focused,
    textarea.if.textarea.is-focused,
    textarea.if.textarea:focus,
    textarea.if:focus {
      border-color: $color-border !important;
      outline: none !important;
    }

    &.label {
      font-family: VolvoNovumRegular, sans-serif !important;
    }

    &.button {
      border-radius: 4px !important;
      border-color: $color-button !important;
      color: $color-button !important;

      &.primary {
        background-color: $color-button !important;
        border-color: $color-button !important;
        color: $color-bg !important;
        &[disabled] {
          opacity: 0.3;
        }
      }

      &.secondary.is-hovered,
      &.secondary:hover {
        box-shadow: inset 0 0 0 1px $color-button !important;
      }

      &.tertiary {
        border-color: transparent !important;
      }
    }

    a.if,
    a.if.is-active,
    a.if:active {
      color: $color-button !important;
    }

    &.textarea.is-invalid ~ label.if,
    &.textarea:invalid:not(:required) ~ label.if,
    &.textarea[invalid] ~ label.if,
    .input-field.is-invalid ~ label.if,
    .input-field:invalid:not(:required) ~ label.if,
    .input-field[invalid] ~ label.if,
    input[type="date"].if.is-invalid ~ label.if,
    input[type="date"].if:invalid:not(:required) ~ label.if,
    input[type="date"].if[invalid] ~ label.if,
    input[type="email"].if.is-invalid ~ label.if,
    input[type="email"].if:invalid:not(:required) ~ label.if,
    input[type="email"].if[invalid] ~ label.if,
    input[type="file"].if.is-invalid ~ label.if,
    input[type="file"].if:invalid:not(:required) ~ label.if,
    input[type="file"].if[invalid] ~ label.if,
    input[type="tel"].if.is-invalid ~ label.if,
    input[type="tel"].if:invalid:not(:required) ~ label.if,
    input[type="tel"].if[invalid] ~ label.if,
    input[type="text"].if.is-invalid ~ label.if,
    input[type="text"].if:invalid:not(:required) ~ label.if,
    input[type="text"].if[invalid] ~ label.if,
    textarea.if.is-invalid ~ label.if,
    textarea.if.textarea.is-invalid ~ label.if,
    textarea.if.textarea:invalid:not(:required) ~ label.if,
    textarea.if.textarea[invalid] ~ label.if,
    textarea.if:invalid:not(:required) ~ label.if,
    textarea.if[invalid] ~ label.if {
      color: $color-error !important;
    }
    a::after,
    .back-to-link::before,
    .if.icon.blue,
    .if.selection-control[type="checkbox"]:checked + label:after,
    .ConfirmationStep .feedback-container .thumbs {
      filter: hue-rotate(-50deg) saturate(0.165) brightness(1.15);
    }
    .back-to-link::before {
      bottom: -0.0375rem;
    }
    .submit-status-holder .submit-status-progress .bar {
      background-color: $color-button;
    }
    &.dropdown-select {
      background-color: #ffffff;
    }
  }
  .card-container > .image .image-container img.placeholder {
    border-color: $color-button;
  }
}

html[data-whatinput="keyboard"].intake.brand-volvia {
  .if.button {
    &.is-focused,
    &:focus {
      outline: 0.25rem solid $color-border;
      border-radius: 0px !important;
    }
  }
}
