.language-selector {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
  .if.dropdown-select {
    display: flex;
    line-height: 100%;
    background-size: 1rem 1rem;
    background-position: right;
    background-color: #f6f3f0;
    width: 2.375rem;
    padding:0;
    border: none;
    font-size: 1rem;
    height: 1.25rem;
  }

  &:before {
    content: "";
    display: inline-block;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg class='nc-icon-wrapper' stroke-linecap='square' stroke-width='1.5' fill='none' stroke='%23331e11' stroke-miterlimit='10'%3E%3Cpath data-cap='butt' data-color='color-2' stroke-linecap='butt' d='M16 1v30M1 16h30'/%3E%3Cellipse data-color='color-2' cx='16' cy='16' rx='8.571' ry='15'/%3E%3Cpath data-cap='butt' data-color='color-2' stroke-linecap='butt' d='M3.31 8h25.38M3.31 24h25.38'/%3E%3Ccircle cx='16' cy='16' r='15'/%3E%3C/g%3E%3C/svg%3E");
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin: auto;
    position: relative;
    margin-right: 8px !important;
  }
}

.workshop-ui .language-selector {
  border: none;
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
  padding-right: 32px;

  .if.dropdown-select {
    background-color: #faf9f7;
  }
}
