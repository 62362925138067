.field-container {
  background-color: #faf9f7;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.record-info-grid {
  .record-info-value {
    font-variation-settings: "wght" 126;
    display: block;
    min-height: 30px;
    a {
      font-variation-settings: "wght" 78;
    }
  }
}
