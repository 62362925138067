.invalid-link-container, .workshop-landing-container {
  text-align: center;
  min-height: 100vh;
  max-width: 800px !important;
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-sizing: border-box;
}

.mail-link {
  text-decoration: none !important;
  color: #0054f0 !important;
}

.invalid-link-logo-container, .landing-logo-container {
  margin-bottom: 1rem !important;
}
