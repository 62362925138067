.PhoneInput {
  order: 2;
  padding: 0 !important;

  &--focus {
    [data-whatinput="keyboard"] & .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
      box-shadow: none;
      outline: 2px solid #0054f0;
      outline-offset: 0px;
    }
    .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
      border-right: 1px solid #6e625e;
      outline: 0;
      box-shadow: none;
    }
  }
  .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: rgba(51, 30, 17, 0.9) !important;
    border-bottom-width: 1.3px;
    border-right-width: 1.3px;
  }
  .PhoneInputCountryIcon--border {
    width: 48px;
    margin: 0px -24px 0px -14px;
    height: calc(100% - 20px);
    padding: 10px 24px 10px 10px;
    background: none;
    box-shadow: none;
    border-right: 1px solid #6e625e;
  }
  .PhoneInputCountry {
    background: #edecea;
    z-index: 10;
    padding-left: 0.8em;
    padding-right: 0.6em;
    margin: 0;
  }

  .selected-flag {
    display: flex !important;

    .selected-dial-code {
      padding-left: 4px !important;
    }
  }

  .if.input-field {
    z-index: 20;
    background: transparent !important;
    padding-left: 0.6em;
  }
}

html {
  &.intake {
    &.brand-volvia {
      .PhoneInputInput {
        border-color: #331e11 !important;
        border-right: 0px;
      }

      .PhoneInputCountry {
        border-radius: 4px;
      }
    }

    .PhoneInput {
      width: 100% !important;
    }
  }

  &.workshop-ui {
    .PhoneInput {
      width: 360px;
    }
  }

  .PhoneInput {
    .if.input-field {
      border-color: transparent !important;
      z-index: 0;
    }

    .if.input-field.is-invalid {
      border-top-color: #bb320c !important;
      border-bottom-color: #bb320c !important;
    }
  }

  .PhoneInputInput {
    width: 100%;
    max-height: 46px;
  }
}
