@import "./shared/actions";

.detail-view {
  max-width: 1900px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    .glass-icon {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      margin-left: 0.4em;
      background: #331e11;
      position: relative;
      top: -0.4em;
    }
  }
  .info-detail-column {
    max-width: 700px;
  }
  .detail-column {
    flex-basis: 100%;
    width: 100%;
    margin-left: 30px;

    .if.back-to-link {
      min-width: auto;

      &:hover {
        border: none;
        box-shadow: none;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .detail-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .detail-column {
      flex: 1;
      width: 50%;
    }
  }
  .status-button {
    margin: 8px 8px 0 0 !important;
  }
  .status-and-decision {
    display: flex;
    flex-direction: row-reverse;

    .if.segmented-control {
      .segment-loading {
        &,
        * {
          cursor: default !important;
        }
        .loaderContainer {
          display: inline-block;
          .loader {
            margin: -1em 1em;
          }
        }
      }
      input[type="radio"] + label {
        min-width: unset;
      }
      input[type="radio"]:checked + label {
        padding-left: 3.5rem;
        background-position: 2rem;
      }
    }

    .status-menu {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;
      .menu {
        top: 2.5em;
        right: 0em;
      }
    }
  }
}
.if.row {
  margin-bottom: 1rem;
}
.comments {
  h3.if {
    margin: 0.5rem 0 1rem 0;
  }
  .comment {
    margin-left: 2rem;
    .comment-header {
      font-weight: bold;
      .date {
        font-weight: normal;
        margin-left: 0.7rem;
        font-size: 1rem;
      }
    }
    .text {
      margin-bottom: 1em;
    }
  }
}
.header-heading-container {
  display: flex;
  align-items: center;
}

.header-brand-logo {
  width: 25%;
  height: 50%;
}

.if.main .if.notification.error {
  padding: 1rem 1.25rem 1em 3.25rem;
  //overriding @if-design-system/notification/src/notification.scss:51
  &:before {
    transform: none;
    left: 1.25rem;
    height: 1.25rem;
    width: 1.25rem;
    background-size: 1.25rem 1.25rem;
  }
}
