.if.pagination {
  display: flex;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
}
.if.pagination>ul.if {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  height: 24px;
  margin: 0 2rem 0 0;
  li {
    display: flex;
  }
}
.if.pagination>ul.if>li.if{
  button.if, .if.icon {
    text-decoration: none;
    font-family: If Sans,Arial,sans-serif;
    font-weight: 78;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: normal;
    font-variation-settings: "wght" 85;
    border: none;
    border-radius: 0;
    background-color: transparent;
    height: 100%;
    width: 1.7rem;
    color: #0054f0;
    cursor: pointer;
  }
  button.if.page-number {
    width: fit-content;
    padding-right: 8px;
    padding-left: 8px;
  }
}
.if.pagination>ul.if>li.if>button.if.current {
  color: #331e11;
}