.stats-page
{
  h4
  {
    margin-bottom: 0.3em;
  }
  .stats-table
  {
    display: grid;
    width: fit-content;
    grid-column-gap: 1em;
    grid-template-columns: auto auto auto;
    .header
    {
      text-align: left;
      white-space: pre;
    }

    .value, .percent
    {
      text-align: right;
    }
  }
}
