.instruction-photos-container {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 auto;

  .image {
    width: 45%;
    margin: 0 auto;

    .image-container {
      width: 100%;
      max-height: 40vh;
      object-fit: contain;

      img {
        max-width: 100%;
        max-height: 35vh;
        border: 2px dashed gray;
        padding: 4px;
      }
    }
  }
}
