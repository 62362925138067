.workshop-ui .workshop-feedback {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;
  .window {
    max-width: 25em;
    background-color: #f6f3f0;
    padding: 2em;
    position: relative;
    h3 {
      margin-top: 0;
    }
    .close-button {
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;
      .icon {
        $size: 1.5rem;
        width: $size;
        height: $size;
        background-size: 100%;
      }
    }
    .buttons {
      text-align: center;
      button.if.button {
        min-width: 0;
        width: auto;
        margin-bottom: 0;
        //margin: 0 1em 1em 1em;
      }
    }
    textarea {
      width: 100%;
    }
  }
}
