@import "~@if-design-system/util/src/variables";
@import "~@if-design-system/color/src/color-variables";

@font-face {
  font-family: "VolvoNovumRegular";
  src: local("VolvoNovumRegular"), url("./fonts/volvia/Volvo Novum-Regular.woff") format("woff");
  font-weight: normal;
  font-display: block;
}

html[data-whatinput="mouse"],
[data-whatintent="touch"],
[data-whatintent="initial"] {
  .if.button:focus,
  .if.selection-control[type="checkbox"]:focus + label {
    outline: none;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-container {
  display: flex;
  height: 100%;
  min-height: 0;
}

.if.main {
  flex: 1 0 0;
  overflow: auto;
}

.if .padded-block {
  padding: $spacing-size-32;
}

.if.heading.largest {
  margin-bottom: 1rem;
}

.if.heading {
  margin: 0 0 1rem;
}

.heading .icon {
  width: 1.3em;
  height: 1.2em;
  display: inline-block;
  background-size: 0.6em 0.6em !important;
  background-position: 50% 50% !important;
  margin-right: 0.2em;
  position: relative;
  top: 0.25em;
}

.heading .icon.new {
  background-color: $color-lightBlue-infographic;
}

.heading .icon.in-progress {
  background-color: $color-lightGreen-infographic;
}

.heading .icon.on-hold {
  background-color: $color-lightYellow-infographic;
}

.heading .icon.archive {
  background-color: $color-lightRed-infographic;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.heading .icon.refresh {
  float: right;
  transition: all 0.3s ease-out;
  //fix transform jump visual bug
  transform: rotate(1deg);
  cursor: pointer;
  //fix icon not centered
  background-position-x: 49.5% !important;

  &:hover {
    transform: rotate(180deg);
  }

  &.spin {
    animation: spin 0.5s infinite linear;
  }
}

button.if.back-to-link {
  padding: 0 12px 0 0;

  &:before {
    content: "";
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    white-space: nowrap;
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 32 32%27%3E%3Ctitle%3Esmall left%3C/title%3E%3Cg class=%27nc-icon-wrapper%27 stroke-linecap=%27square%27 stroke-linejoin=%27miter%27 stroke-width=%271.5%27 fill=%27%230054f0%27 stroke=%27%230054f0%27%3E%3Cpolyline fill=%27none%27 stroke=%27%230054f0%27 stroke-miterlimit=%2710%27 points=%2719,22 13,16 19,10 %27/%3E%3C/g%3E%3C/svg%3E");
    background-position: left center;
    background-size: 1.5rem 1.5rem;
    background-repeat: no-repeat;
    margin: 0;
    vertical-align: middle;
    bottom: 0.0625rem;
    left: -0.5rem;
  }
}
.back-to-link {
  cursor: pointer;
  margin: 0 !important;
  &:before {
    position: relative;
    top: -0.07em;
  }
}

label.icon {
  padding-left: 1.2em;
  background-position-x: 0 !important;
}

label.if {
  line-height: 1;
}

.if.actions .if.button {
  min-width: unset;
  padding: 0.5rem 1rem;
}

.if.actions {
  margin: 1rem 0px !important;
}

.if.no-items {
  text-align: center;
  margin: 2em;
  font-style: italic;
  font-weight: 100;
  font-family: "If Sans Thin", "Arial", sans-serif;
  font-size: 1.5em;
}

span.version {
  color: #bbb;
  margin: 0 0.5em;
}

.workshopButton {
  position: relative;
  .if.icon.product.vehicles {
    margin-left: 0.6em;
    margin-right: 0.1em;
  }
  .if.icon.ui.drop-right {
    height: 2em;
    width: 2em;
    background-size: 2em !important;
    margin-right: -1em;
  }
}

.one-line {
  white-space: nowrap;
}

.menu-item-text {
  font-family: If Sans, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  width: 100%;
  font-variation-settings: "wght" 85;
  color: #331e11;
  height: 2rem;
  line-height: 2rem;
  text-overflow: ellipsis;
  padding: 0 1.5rem;
  cursor: default;
}

.corner-left-round.menu-icon {
  transform: rotate(180deg);
  position: relative;
  top: 0.3em;
  margin-left: 1em;
  margin-right: 0.7em !important;
}

.lightweight-dropdown
{
  font-size: 0.8em;
  padding: 1em;
}

.heading .subtitle {
  font-size: 18px;
  color: #6E625E;
  font-weight: bold;
  margin-bottom: 2em;
  line-height: 1em;
}
