@import "~@if-design-system/util/src/variables";

.if.actions {
  margin: $spacing-size-32 0;
  .if.button {
    margin: -2px -2px 0 0;
  }
  &.admin
  {
    opacity: 0.3;
    &:hover
    {
      opacity: 1;
    }
    .if.button
    {
      font-size: 0.6em;
      padding: 0.1em 0.4em;
      height: 1.8em;
      margin: 0 2px 0 0 !important;
      background: gray;
      border: 0;
      &.delete
      {
        background: #b35b5b;
      }
    }
  }
}
