.feedback-page {
  position: relative;
  .export-button {
    position: absolute;
    right: 20px;
  }
  .stats {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .stats-box {
      margin: 2em 1em;
      display: flex;
      align-items: center;
      background: white;
      padding: 1em 2em;
      flex: 1;
      max-width: 14em;
      .text {
        height: 7em;
        display: flex;
        flex-direction: column;
        flex: 1;
        .val {
          font-size: 3em;
          margin: 0.4em 0;
          font-variation-settings: "wght" 48;
        }
      }
      .icon {
        width: 2em;
        height: 2em;
        margin-left: 2em;
        background-size: 100% !important;
      }
    }
  }
  table {
    tr {
      td {
        vertical-align: top;
        padding: 0.5em !important;
      }
      &:hover {
        background-color: white;
      }
      cursor: pointer;
      td.thumb {
        width: 2em;
        position: relative;
        &.up {
          top: -0.5em;
        }
      }
      td.registration-number {
        width: 4em;
      }
      .email {
        width: 10em;
      }
      td.timestamp {
        width: 8em;
      }
    }
  }
}
