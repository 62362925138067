table.if {
  .if.record-row {
    cursor: pointer;
    height: 60px;
    &:hover {
      background-color: white;
    }
  }
  tbody > tr > td {
    line-height: normal;
    padding: 0.5rem 1rem;
  }

  thead {
    th {
      color: black !important;
      user-select: none;
      .if.sort {
        filter: brightness(0.2);
        position: relative !important;
        top: 0.1em;
      }
    }
  }

  td a {
    text-decoration: none;
  }

  .actionColumn {
    cursor: default;
  }

  .actionHeader {
    width: 80px;
  }
  tr.is-warning-phin {
    background-color: #fcedbd;
    &.record-row:hover {
      background-color: lighten(#fcedbd, 5%);
    }
    .icon-offset {
      position: relative;
      display: inline-block;
      width: 0;
      left: -1.3em;
      top: 0.2em;
      .icon.ui.info {
        width: 1em;
        height: 1em;
        display: inline-block;
      }
    }
  }
}

.pagination {
  height: 24px;
  margin-top: 24px !important;

  ul.if {
    height: 24px !important;
  }
  div {
    min-width: 1em;
    text-align: right;
    display: inline-block;
    margin-right: 0.4em;
  }

  .description strong
  {
    margin: 0 0.5em;
  }

  button {
    color: #331e11;
    background: none;
    border: 0;
    height: 2em;
    margin: 0 0.5em;
    padding: 0 0.5em 0 0.7em;
    display: inline-block;
    font-size: 1.2em;
    cursor: pointer;
    outline-color: rgba(51, 30, 17, 0.5);

    &:first-child {
      padding: 0em 0.7em 0 0.5em;
    }

    &:hover {
      background-color: white;
    }
  }
}
.if.table-container.box {
  padding: 1rem;
}
.if.menu {
  max-width: 500px;
}

.if.table-filters {
  flex-basis: 25em;
  justify-content: flex-end;
}

table.if.record-table {
  td.if.actionColumn {
    padding: 0 1em !important;
  }
}

.if.table-toolbar {
  padding-top: 0;
  padding-bottom: 0.5rem;
  justify-content: end;
}
.if.tabs li, .if.tabs li a {
  cursor: pointer;
}
.if.tabs
{
  display: inline-block;
}
