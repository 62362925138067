.test-page
{
  zoom: 0.8;
  h1, h2
  {
    margin-bottom: 0.2em;
  }
  .if.input-wrapper
  {
    margin: 0 0 1rem;
  }
  .sms-template
  {
    min-height: 26em;
  }
  .sms-template-header
  {
    min-height: 3em;
  }
  .sms-template-text, 
  .sms-template-header
  {
    line-height: 1.4em;
    white-space: pre-wrap;
  }
  .sms-template-text
  {
    font-size: 1rem;
    margin: 2em 0 0 0;
    max-width: 15em;
    word-break: break-word;
    padding: 0.5em;
    background: white;
    border-radius: 0.5em;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  .if.selection-controls
  {
    min-height: unset;
    label
    {
      font-size: 0.8em !important;
      margin-right: 0.7em !important;
      height: 3em !important;
      &::before
      {
        margin-right: 0.3em !important;
        height: 1.5em !important;
        width: 1.5em !important;

      }
    }
  }
}
