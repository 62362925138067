input[type="number"].if {
  text-align: left;
}

input.if.date {
  font-family: If Sans, Arial, sans-serif;
  padding: 0.5rem 1.25rem;
  font-size: 1.125rem;
  line-height: 28px;
  background: #faf9f7;
  color: #331e11;
  width: 50%;
  box-sizing: border-box;
  min-width: 11em;
}

input[type="date"].if-date.is-invalid ~ .if.input-error,
input[type="date"].if-date:invalid:not(:required) ~ .if.input-error {
  display: block;
}

input[type="date"].if-date[value=""],
#input-claimDate:invalid:not(.is-invalid)
{
  border-color: #6e625e;
  border-width: 1px;
}
#input-claimDate:invalid:not(.is-invalid)~.if.input-error
{
  display: none;
}

input[type="date"]:not(if-date[value=""]).is-invalid {
  border-color: #bb320c !important;
}
