.brand-volvia {
  .volvia-header-logo {
    margin-bottom: 0.2rem !important;
    text-align: center;
  }

  .volvia-landing-image {
    width: 80%;
    max-width: 241px;
  }

  .volvia-landing-container {
    text-align: center;
    margin-bottom: 0.5rem;

    h3 {
      margin-left: unset !important;
    }
  }

  .loader-container {
    margin-bottom: 2rem;
  }
}
