@import "./steps/volvia/intakeUiVolvia";

html.intake {
  width: 100%;
  height: auto;
  overflow: visible;
  body {
    width: 100%;
    #root {
      width: 100%;
      height: auto;
      display: block;
      color: #331e11;
      background-color: #f6f3f0;
      min-height: 100vh;
      .centered {
        margin: 2em auto;
      }
      .padded {
        padding: 2em;
      }
      .step-container {
        display: block;
        height: fit-content !important;
        overflow: hidden !important;

        max-width: 800px !important;

        @media only screen and (max-width: 720px) {
          max-width: 690px !important;
        }

        .input-wrapper {
          max-width: 400px;
        }
        &.LandingStep {
          padding-bottom: 4em;
        }
      }

      h1.if {
        margin: 15px 0;
        font-size: 32px;
        line-height: 60px;
      }
      .basic-text,
      p.if {
        margin: 15px 0;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  .branded {
    display: none;
  }

  &[data-whatintent="touch"] {
    * {
      outline: none !important;
    }
  }
}
