.validation-message {
  width: unset !important;
}

.smaller-input {
  width: 50% !important;
}

.textarea-dimensions {
  min-height: 200px !important;
  max-width: 100% !important;
}
