@import "~@if-design-system/color/src/color-variables";
@import "~@if-design-system/util/src/variables";
$tag-border-opacity: 0.2;
.if.tag {
  font-size: 1rem !important;
  background-color: white;
  margin: $spacing-size-8;
  padding: 0 $spacing-size-8;
  display: inline-block;
  border: 1px solid gray;
  font-weight: bold;
  position: relative;
  margin-bottom: 1.6rem;
  &.New {
    background-color: $color-lightBlue-infographic;
    border-color: rgba($color-blue-infographic, $tag-border-opacity);
  }
  &.InProgress {
    background-color: $color-lightGreen-infographic;
    border-color: rgba($color-green-status, $tag-border-opacity);
  }
  &.OnHold {
    background-color: $color-lightYellow-infographic;
    border-color: rgba($color-red-infographic, $tag-border-opacity);
  }
  &.Closed {
    background-color: $color-lightRed-infographic;
    border-color: rgba($color-red-infographic, $tag-border-opacity);
  }
}
