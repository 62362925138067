html.intake {
  .ConfirmationStep
  {
    h1
    {
      line-height: 1.1em;
    }
    .feedback-container
    {
      margin-bottom: 3em;
      h2
      {
        font-weight: 100;
        text-align: center;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
        font-variation-settings: "wght" 48;
      }
      .thumbs
      {
        margin: 2em auto;
        display: flex;
        justify-content: center;
        button
        {
          border: none;
          background: none;
          margin: 0 1em;
          cursor: pointer;
          img
          {
            width: 2.5em;
            height: 2.5em;
            &:hover
            {
              transform: scale(1.1);
            }
          }
        }
      }
      .text
      {
        .input-wrapper
        {
          margin: 1.5em auto;
          max-width: 25em;
        }
        .button
        {
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}
