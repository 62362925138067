.menuIcon {
  display: block;
  width: 30px;
  height: 30px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  background-size: unset !important;

  &:hover {
    -webkit-transform: rotate(90deg) scale(1.2);
    -moz-transform: rotate(90deg) scale(1.2);
    -o-transform: rotate(90deg) scale(1.2);
    -ms-transform: rotate(90deg) scale(1.2);
    transform: rotate(90deg) scale(1.2);
  }
}

.menuButton {
  border-width: 0px;
  background-color: transparent;
  cursor: pointer;
  outline-width: 0px;
  margin-top: 5px;
  padding-left: 10px;
}

.menuContainer {
  min-width: 180px !important;
  top: 3em !important;
  left: unset !important;
  right: 2em !important;

  abbr > * {
    cursor: help;
    font-size: 1rem;
    font-variation-settings: "wght" 82;
    font-family: If Sans, Arial, sans-serif;
    height: 2rem;
    padding: 0 1.5rem;
    display: flex;
    white-space: nowrap;
  }
}
