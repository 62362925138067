.info-notifications {
  display: flex;
  flex-direction: column;
  .info-notification {
    width: 100%;
    min-height: unset;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 1.5rem;
    margin: 0;
    p {
      max-width: calc(100% - 4em);
    }
    .if.button {
      width: 1.5rem;
      min-width: 1.5rem;
      height: 1.5rem;
      padding: 0;
      border: none;

      .if.icon {
        margin: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    &:before {
      display: none;
    }
  }
  .workshop-landing-container > & {
    margin-bottom: 2em;
    width: 100%;
    text-align: left;
  }
}
