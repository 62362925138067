.edit-notifications {
  .info {
    margin: 1em 0;
    font-size: 0.7em;
  }
  .notification {
    padding: 0.3em 1em;
    margin-top: 0.5em;
    background-color: white;
    display: grid;
    width: 100%;
    max-width: 35em;
    grid-template-columns: 8em auto;
    row-gap: 0em;
    align-items: baseline;
    zoom: 0.7;
    &.new {
      padding: 1.5em 1em;
      row-gap: 1em;
    }
    .date {
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: baseline;
      column-gap: 1em;
    }
    .input-wrapper {
      margin: 0;
    }
    .input-wrapper,
    input.if,
    textarea.if {
      width: 100%;
    }
    select.if {
      width: 5.5em;
    }
    textarea.if {
      padding: 0.3em 0.6em;
    }
    & > div {
    }
    .icon {
      display: inline-block;
      margin: 0 0.5em 0 0;
      width: 1em;
      height: 1em;
      position: relative;
      top: 0.2em;
    }
    button.primary {
      padding: 0 2em;
      width: auto;
      min-width: unset;
    }
    button.tertiary {
      padding: 0 0.5em;
      margin: -0.4em 0 -0.2em 1em;
      width: auto;
      min-width: unset;
      position: relative;
      top: 0.3em;
      .icon {
        top: 0;
        margin-right: 0.3em !important;
      }
    }
  }
}
