.sidenav-container {
  background-color: #f1ece8;
  overflow-x: hidden;
  a {
    text-decoration: none;
    font-size: 16px;
    color: #321d11;
  }

  flex: 0 0 16rem !important;

  display: flex;
  flex-direction: column;
  .new-inspection-container,
  .link-container {
    margin: 17px;
    vertical-align: middle;
    .button {
      min-width: unset;
      width: 100%;
    }
  }
  .link-container {
    margin-top: 0.3em;
  }
  .spacer {
    flex: 1 1;
  }

  .new-inspection-item {
    padding: 14px 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #0054f0 !important;
    height: 48px;
    font-size: 16px;
  }
  .admin-separator {
    height: 1px;
    background: #786d69;
    margin: 0.5em 1.9em 1.1em;
  }
  .section-header {
    font-weight: bold;
    margin: 0.8em 1em 0.2em;
  }
  .sidenav-item-container + .section-header
  {
    margin-top: 0.6em;
  }

  .icon-text {
    display: flex;
    position: relative;
    > .icon {
      display: block;
      width: 16px;
      background-size: unset !important;
    }
    .text {
      line-height: 16px !important;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 9.8px;
    }
  }
  .is-expandable {
    margin: 0 0 25px 0 !important;
  }
  .title {
    font-family: If Sans Bold, Arial, sans-serif !important;
    font-weight: 400 !important;
    font-variation-settings: "wght" 126 !important;
    background-color: #f1ece8 !important;
    margin: 0 !important;
    margin-bottom: 7px !important;
    height: 24px !important;
    width: auto !important;
    padding-left: 17px !important;
    padding-right: 13px !important;
    &:after {
      height: 24px !important;
      width: 24px !important;
      background-size: 24px 24px !important;
      background-origin: content-box !important;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='none' stroke-miterlimit='10' d='M22 13l-6 6-6-6' stroke-linecap='square' stroke-width='1.5' stroke='%23331e11'/%3E%3C/svg%3E") !important;
      padding-right: 13px !important;
      padding-left: 13px !important;
      right: 0 !important;
    }
  }
  .content {
    padding: 0 !important;
  }
}


