.intake {
  .step-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 24px 16px;
    position: relative;
  }

  @media only screen and (max-width: 1366px) {
    .if.button.back-arrow {
      width: 1px;
      position: absolute;
      padding: 0.5rem 1.5rem;
      border: 0;
    }

    .div-left {
      text-align: left;
    }

    .previousButton {
      display: none;
    }

    h3 {
      text-align: left;
      margin-left: 60px !important;
      padding-top: 10px !important;
    }

    .first {
      margin-left: 0 !important;
      padding-top: 10px !important;
    }

    .mobile-safe-area-buffer {
      min-height: 150px;
    }
  }

  .header-text-with-back-button {
    text-align: left;
    padding-right: 0 !important;
  }

  .basic-text {
    text-align: left;
    width: 100% !important;
  }
  .basic-text-centered {
    text-align: center;
    width: 100% !important;
  }

  .header-text {
    text-align: left;
    margin-left: unset !important;
  }
  .header-text-centered {
    text-align: center;
    margin-left: unset !important;
  }

  @media only screen and (min-width: 1367px) {
    .if.button.back-arrow {
      display: none;
    }

    .previousButton {
      display: block;
    }

    .header-text-with-back-button {
      padding-right: 0 !important;
    }
  }

  .normal-input {
    max-width: unset !important;
  }
}
